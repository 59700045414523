<template>
  <div class="about-wrapper">
    <div class="banner-container">
      <div class="review_head">
        <div class="left"><a href="/review">杭州魔筷科技有限公司</a></div>
        <div class="right"><a href="/about-copy">关于我们</a></div>
      </div>
      <div class="title-box">
        <h1 class="top">魔筷科技 成就有梦想的人</h1>
        <div class="bottom">
          <img
            src="https://mktv-in-cdn.mockuai.com/16275238307684992.png"
            alt=""
            class="title"
          />
        </div>
      </div>
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, index) in bannerData" :key="index">
          <div class="banner-item">
            <img :src="item.image" alt="" class="main" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination"></div>
    </div>
    <div class="text-intro">
      <h2 class="title">关于魔筷</h2>
      <div class="content-box">
        <div class="info">
          <div class="company-title">魔筷科技</div>
          <p class="desc">
            魔筷科技成立于2015年，是直播电商供应链服务平台，得到了快手、腾讯的战略投资。公司致力于构建直播电商生态下的全链条数字化能力，并以SaaS、供应链、运营等一体化服务，赋能中小主播，重构零售产业链，并为消费者带来创新、优质的社交购物体验。
          </p>
          <p class="desc">
            目前，公司已链接了数百万主播，数万家工厂及品牌。未来，魔筷科技将继续深耕于直播电商领域，以更优异的技术和服务，促进全球数字经济产业的持续繁荣。
          </p>
          <div class="subtitle">
            <span class="text">使命</span>
            <span class="content">成就有梦想的人</span>
          </div>
          <div class="subtitle">
            <span class="text">愿景</span>
            <span class="content"
              >构建智慧高效的直播供应链服务能力，成就百万有梦想的人！</span
            >
          </div>
        </div>
        <img
          src="https://mktv-in-cdn.mockuai.com/16273710662873366.png"
          alt=""
          class="building"
        />
      </div>
    </div>
    <div class="video-intro">
      <div class="banner-box">
        <h2 class="title">魔筷科技宣传片</h2>
        <div class="video-box">
          <video
            ref="introVideo"
            src="https://mktv-in-cdn.mockuai.com/16273731790055925.mp4"
            controls
            class="video"
          ></video>
          <div v-show="posterVisible" class="poster" @click="play">
            <img
              src="https://mktv-in-cdn.mockuai.com/16273746398668299.png"
              alt=""
              class="main"
            />
            <img
              src="https://mktv-in-cdn.mockuai.com/16273747010729705.png"
              alt=""
              class="play-icon"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="management-container">
      <h2 class="title">魔筷资方背景</h2>
      <div
        v-for="(cards, index) in displayCards"
        :key="index"
        class="cards-row"
      >
        <div v-for="(item, i) in cards" :key="i" class="card-item">
          <img :src="item" alt="" class="main" />
        </div>
      </div>
    </div>
    <div class="founder-container">
      <h2 class="title">创始人团队</h2>
      <div class="cards">
        <div
          v-for="(item, index) in founderData"
          :key="index"
          class="card-item"
        >
          <img :src="item.image" alt="" class="main" />
          <div class="info">
            <div class="name">{{ item.name }}</div>
            <div class="post">{{ item.post }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="develop-container">
      <div class="develop-box">
        <h2 class="title">发展历程</h2>
        <div class="content-box">
          <img
            src="https://mktv-in-cdn.mockuai.com/16273797208757040.png"
            alt=""
            class="main"
          />
          <div class="timeline">
            <div
              v-for="(item, index) in timelineData"
              :key="index"
              class="time-item"
            >
              <div class="year">{{ item.year }}</div>
              <div class="info">
                <div class="title">{{ item.title }}</div>
                <div class="items">
                  <div v-for="(p, i) in item.items" :key="i" class="item">
                    {{ p }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="honor-box">
          <h2 class="title">公司荣誉</h2>
          <div class="swiper">
            <div class="swiper-arrow prev"></div>
            <div class="swiper-arrow next"></div>
            <swiper :options="honorSwiperOption">
              <swiper-slide v-for="(item, index) in honorData" :key="index">
                <div class="honor-item">
                  <img :src="item" alt="" class="main" />
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  head: {
    title: "魔筷科技—成就有梦想的人",
    meta: [
      {
        name: "Keywords",
        content:
          "魔筷科技  直播电商  魔筷发展历程  魔筷公司  杭州魔筷科技   魔筷星选  快手服务商",
      },
      {
        name: "Description",
        content:
          "魔筷科技成立于2015年，是直播电商供应链服务平台，得到了快手、腾讯的战略投资。以“成就有梦想的人”为使命，以“构建智慧高效的直播供应链服务能力，成就百万有梦想的人！”为愿景，深耕于直播电商领域，促进全球数字经济产业的持续繁荣。",
      },
    ],
  },
  data() {
    return {
      posterVisible: true,
      bannerData: [
        {
          image: "https://mktv-in-cdn.mockuai.com/16273697130156318.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16273697130253760.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16273697130213540.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16273697130293655.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16273697130318300.png",
        },
        {
          image: "https://mktv-in-cdn.mockuai.com/16273697130345944.png",
        },
      ],
      swiperOption: {
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      cardsData: [
        "https://mktv-in-cdn.mockuai.com/16273761297054405.png",
        "https://mktv-in-cdn.mockuai.com/16273761296709276.png",
        "https://mktv-in-cdn.mockuai.com/16273761297021390.png",
        "https://mktv-in-cdn.mockuai.com/16273761296814947.png",
        "https://mktv-in-cdn.mockuai.com/16273761296868259.png",
        "https://mktv-in-cdn.mockuai.com/16273761296775467.png",
        "https://mktv-in-cdn.mockuai.com/16273761296992824.png",
        "https://mktv-in-cdn.mockuai.com/16273761296951582.png",
        "https://mktv-in-cdn.mockuai.com/16273761296978886.png",
        "https://mktv-in-cdn.mockuai.com/16273761296923946.png",
        "https://mktv-in-cdn.mockuai.com/16273761296894677.png",
      ],
      founderData: [
        {
          name: "王玉林",
          post: "魔筷科技创始人&CEO",
          image: "https://mktv-in-cdn.mockuai.com/16273770104153950.png",
          desc: "国内首批淘客、站长。原阿里技术专家、产品经理，负责淘宝店铺、生意参谋、手机淘宝等多款产品的设计。曾任唯一优品母婴电商公司副总裁。2015年创办魔筷科技。",
        },
        {
          name: "曾章强",
          post: "魔筷科技联合创始人&COO",
          image: "https://mktv-in-cdn.mockuai.com/16273770104196561.png",
          desc: "原阿里巴巴技术专家，供职于淘宝、阿里云，负责淘宝多媒体平台、淘宝CRM平台、图片空间和阿里云RDS平台等多个大型系统的设计与研发工作。2015年联合创办魔筷科技。",
        },
        {
          name: "黄冬林",
          post: "魔筷科技联合创始人",
          image: "https://mktv-in-cdn.mockuai.com/16273770104229395.png",
          desc: "原淘宝无线事业部高级项目经理，负责客服系统、手机淘宝等产品研发。原唯一优品项目经理。2015年联合创办魔筷科技。",
        },
      ],
      timelineData: [
        {
          year: "2021",
          title: "被评为国家高新技术企业",
        },
        {
          year: "2020",
          title: "获得腾讯战略投资",
          items: ["被评为杭州准独角兽企业", "11月,接入视频号"],
        },
        {
          year: "2019",
          title: "上线供应服务平台",
          items: ["10月,接入腾讯系直播平台"],
        },
        {
          year: "2018",
          title: "获得快手战略投资",
          items: ["唯品会战略投资", "9月,接入快手App"],
        },
        {
          year: "2017",
          title: "被评为杭州高新技术企业",
        },
        {
          year: "2015",
          title: "魔筷科技成立",
          items: ["大型电商系统，电商SaaS工具"],
        },
      ],
      honorSwiperOption: {
        slidesPerView: 4,
        spaceBetween: "2.7777777777777777%",
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
        navigation: {
          nextEl: ".swiper-arrow.next",
          prevEl: ".swiper-arrow.prev",
        },
      },
      honorData: [
        "https://mktv-in-cdn.mockuai.com/16275253417601881.png",
        "https://mktv-in-cdn.mockuai.com/16275253417578991.png",
        "https://mktv-in-cdn.mockuai.com/16275253417557926.png",
        "https://mktv-in-cdn.mockuai.com/16275253417527535.png",
        "https://mktv-in-cdn.mockuai.com/16275253417494363.png",
        "https://mktv-in-cdn.mockuai.com/16275253417469702.png",
        "https://mktv-in-cdn.mockuai.com/16275253417444983.png",
        "https://mktv-in-cdn.mockuai.com/16275253417418245.png",
        "https://mktv-in-cdn.mockuai.com/16275253417374872.png",
        "https://mktv-in-cdn.mockuai.com/16275253417345946.png",
        "https://mktv-in-cdn.mockuai.com/16275253417298997.png",
        "https://mktv-in-cdn.mockuai.com/16275253417255527.png",
        "https://mktv-in-cdn.mockuai.com/16275253417182039.png",
        "https://mktv-in-cdn.mockuai.com/16279575685797578.png",
      ],
    };
  },
  computed: {
    displayCards() {
      return [
        this.cardsData.slice(0, 2),
        this.cardsData.slice(2, 6),
        this.cardsData.slice(6, 11),
      ];
    },
  },
  methods: {
    play() {
      this.posterVisible = false;
      this.$refs.introVideo.play();
    },
  },
};
</script>


<style lang="less" scoped>
@import "../style/common.less";
.review_head {
  height: 90px;
  display: flex;
  justify-content: space-between;
  margin: 0 240px;
  align-items: center;
  font-size: 1.5vw;
  font-weight: 600;
  color: #333;

  .left,
  .right {
    cursor: pointer;
    a {
      text-decoration: none;
      color: #333;
    }
  }

  .right {
    font-weight: 400;
  }
  .right:hover {
    border-bottom: 3px solid #ff6600;
  }
}
.about-wrapper {
  .banner-container {
    position: relative;
    .pxToVw(height, 680);
    .title-box {
      position: absolute;
      z-index: 2;
      .pxToVw(top, 249);
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      .top {
        .pxToVw(margin-bottom, 13);
        .pxToVw(line-height, 112);
        .pxToVw(font-size, 80);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .bottom {
        .pxToVw(width, 907);
        margin: 0 auto;
        .pxToVw(height, 33);
        .title {
          width: 100%;
        }
      }
    }
    .banner-item {
      width: 100vw;
      .pxToVw(height, 680);
      .main {
        width: 100%;
      }
    }
    .swiper-pagination {
      position: absolute;
      .pxToVw(bottom, 60);
      left: 50%;
      transform: translateX(-50%);
      /deep/ .swiper-pagination-bullet {
        .pxToVw(width, 24);
        .pxToVw(height, 6);
        .pxToVw(border-radius, 4);
        background-color: #fff;
        .pxToVw(margin-right, 8);
        &:last-child {
          margin-right: 0;
        }
        &.swiper-pagination-bullet-active {
          background-color: #ff6600;
        }
      }
    }
  }
  .text-intro {
    box-sizing: border-box;
    .pxToVw(height, 776);
    .pxToVw(padding-top, 91);
    > .title {
      .pxToVw(margin-bottom, 51);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .content-box {
      display: flex;
      .info {
        .pxToVw(margin-left, 240);
        .pxToVw(padding-top, 17);
        .company-title {
          position: relative;
          .pxToVw(margin-bottom, 27);
          .pxToVw(line-height, 30);
          .pxToVw(font-size, 24);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          &::after {
            content: "";
            position: absolute;
            .pxToVw(bottom, -13);
            left: 0;
            .pxToVw(width, 28);
            .pxToVw(height, 6);
            background-color: #ff6600;
          }
        }
        .desc {
          margin: 0;
          .pxToVw(width, 520);
          margin-bottom: 1em;
          .pxToVw(line-height, 30);
          .pxToVw(font-size, 18);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          &:last-of-type {
            .pxToVw(margin-bottom, 50);
          }
        }
        .subtitle {
          display: flex;
          align-items: flex-end;
          .pxToVw(margin-bottom, 32);
          .text {
            .pxToVw(margin-right, 6);
            .pxToVw(font-size, 24);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
          }
          .content {
            .pxToVw(width, 520);
            .pxToVw(font-size, 18);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
      .building {
        .pxToVw(width, 1100);
        .pxToVw(height, 584);
      }
    }
  }
  .video-intro {
    box-sizing: border-box;
    .pxToVw(height, 1129);
    .banner-box {
      box-sizing: border-box;
      .pxToVw(height, 680);
      .pxToVw(padding-top, 95);
      background: url(https://mktv-in-cdn.mockuai.com/16273731699099359.png) top
          left/cover no-repeat,
        linear-gradient(270deg, #001554 0%, #00092a 100%);
      > .title {
        .pxToVw(margin-bottom, 74);
        text-align: center;
        .pxToVw(line-height, 50);
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .video-box {
        position: relative;
        .pxToVw(width, 1440);
        .pxToVw(height, 810);
        .pxToVw(border-radius, 16);
        overflow: hidden;
        margin: 0 auto;
        .video {
          width: 100%;
          height: 100%;
        }
        .poster {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          cursor: pointer;
          .main {
            width: 100%;
            height: 100;
          }
          .play-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .pxToVw(width, 92);
            .pxToVw(height, 92);
          }
        }
      }
    }
  }
  .management-container {
    box-sizing: border-box;
    .pxToVw(height, 848);
    .pxToVw(padding-top, 92);
    background: url(https://mktv-in-cdn.mockuai.com/16273757598925055.png) 0 0 /
        cover no-repeat,
      linear-gradient(270deg, #001554 0%, #00092a 100%);
    > .title {
      .pxToVw(margin-bottom, 86);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    .cards-row {
      display: flex;
      align-items: center;
      .pxToVw(width, 1440);
      margin: 0 auto;
      .card-item {
        .pxToVw(height, 140);
        .pxToVw(margin-right, 40);
        .pxToVw(margin-bottom, 40);
        .main {
          height: 100%;
        }
      }
    }
  }
  .founder-container {
    box-sizing: border-box;
    .pxToVw(height, 913);
    .pxToVw(padding-top, 91);
    > .title {
      .pxToVw(margin-bottom, 72);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .cards {
      display: flex;
      align-items: center;
      .pxToVw(width, 1440);
      margin: 0 auto;
      .card-item {
        position: relative;
        .pxToVw(width, 440);
        .pxToVw(height, 600);
        .pxToVw(margin-right, 60);
        .pxToVw(border-radius, 16);
        overflow: hidden;
        &:last-child {
          margin-right: 0;
        }
        .main {
          width: 100%;
        }
        .info {
          visibility: visible;
          position: absolute;
          .pxToVw(left, 39);
          .pxToVw(bottom, 37);
          .name {
            .pxToVw(margin-bottom, 10);
            .pxToVw(line-height, 33);
            .pxToVw(font-size, 24);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
          .post {
            .pxToVw(line-height, 25);
            .pxToVw(font-size, 18);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .desc {
          // position: absolute;
          // .pxToVw(left, 39);
          // .pxToVw(top, 463);
          // visibility: hidden;
          display: none;
          .pxToVw(margin-top, 12);
          .pxToVw(width, 360);
          .pxToVw(line-height, 25);
          .pxToVw(font-size, 18);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.8);
        }
        &:hover {
          // .info {
          //   visibility: hidden;
          // }
          .desc {
            // visibility: visible;
            display: block;
          }
        }
      }
    }
  }
  .develop-container {
    position: relative;
    box-sizing: border-box;
    .pxToVw(height, 1679);
    background-color: #fff;
    .develop-box {
      position: relative;
      box-sizing: border-box;
      .pxToVw(height, 1328);
      .pxToVw(padding-top, 90);
      background: url(https://mktv-in-cdn.mockuai.com/16273793646096852.png) top
          left/cover no-repeat,
        linear-gradient(270deg, #001554 0%, #00092a 100%);
      > .title {
        .pxToVw(margin-bottom, 86);
        text-align: center;
        .pxToVw(line-height, 50);
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .content-box {
        display: flex;
        .main {
          .pxToVw(width, 960);
          .pxToVw(height, 643);
          .pxToVw(margin-right, 120);
        }
        .timeline {
          position: relative;
          .time-item {
            position: relative;
            display: flex;
            .pxToVw(padding-bottom, 48);
            // .pxToVw(padding-left, 73);
            &::before {
              content: "";
              position: absolute;
              .pxToVw(top, 4);
              .pxToVw(width, 29);
              .pxToVw(height, 23);
              background-image: url(https://mktv-in-cdn.mockuai.com/16273796532383646.png);
              background-size: cover;
              background-repeat: no-repeat;
            }
            &::after {
              content: "";
              position: absolute;
              .pxToVw(left, 11);
              .pxToVw(top, 27);
              height: calc(100% - 23 * @unitVw);
              .pxToVw(width, 2);
              background-color: #fff;
            }
            &:last-child {
              padding-bottom: 0;
              &::after {
                content: "";
                width: 0;
                height: 0;
              }
            }
            .year {
              .pxToVw(margin-left, 73);
              .pxToVw(margin-right, 40);
              .pxToVw(line-height, 33);
              .pxToVw(font-size, 24);
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
            .info {
              .pxToVw(padding-top, 4);
              .title {
                .pxToVw(margin-bottom, 12);
                .pxToVw(line-height, 25);
                .pxToVw(font-size, 18);
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
              }
              .items {
                .item {
                  .pxToVw(margin-bottom, 12);
                  .pxToVw(line-height, 25);
                  .pxToVw(font-size, 18);
                  font-family: PingFangSC-Light, PingFang SC;
                  font-weight: 300;
                  color: rgba(255, 255, 255, 0.8);
                }
              }
            }
          }
        }
      }
    }
    .honor-box {
      position: absolute;
      width: 100%;
      .pxToVw(bottom, -278);
      > .title {
        .pxToVw(margin-bottom, 80);
        text-align: center;
        .pxToVw(line-height, 50);
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .swiper {
        position: relative;
        .pxToVw(width, 1440);
        margin: 0 auto;
        .swiper-arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          .pxToVw(width, 44);
          .pxToVw(height, 44);
          background-size: cover;
          background-repeat: no-repeat;
          cursor: pointer;
          &.prev {
            .pxToVw(left, -44);
            background-image: url(https://mktv-in-cdn.mockuai.com/16274920200113393.png);
          }
          &.next {
            .pxToVw(right, -44);
            background-image: url(https://mktv-in-cdn.mockuai.com/16274920199899246.png);
          }
        }
        .honor-item {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          .pxToVw(width, 330);
          .pxToVw(height, 466);
          background-color: #fff;
          .main {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
